<template>
    <div>
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <b>Course(s) to Tag</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" class="mb-2">
                                <label><b>Course</b></label>
                                <multiselect v-model="selected_course" 
                                :options="course.non_active_courses" 
                                :searchable="true" 
                                :close-on-select="true" 
                                :show-labels="false" 
                                :clear-on-select="false"
                                label="name"
                                track-by="id"
                                placeholder="Select a Course"
                                ></multiselect>
                            </CCol>
                            <CCol sm="4">
                                <label><b>Year</b></label>
                                <CInput  type="text" v-model="selected_course.year" ></CInput>
                            </CCol>
                            <CCol sm="4">
                                <label><b>Level</b></label>
                                <CInput  type="text" v-model="selected_course.level" ></CInput>
                            </CCol>
                            <CCol sm="4">
                                <label><b>Subject</b></label>
                                <CInput  type="text" v-model="selected_course.subject" ></CInput>
                            </CCol>
                           
                        </CRow>

                    </CCardBody>
                    <CCardFooter>
                        <CButton @click="addToCourses()" style="background-color:#998542; color:white">
                            Add To Active Courses
                        </CButton>
                    </CCardFooter>
                </CCard>
                <CCard>
                    <CCardHeader>
                        <b>Active Courses</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <CDataTable 
                                :items="course.active_courses"
                                :fields="fields"
                                :noItemsView='{ noResults: "No Tag Items Found", noItems: "No Tags Found" }'
                                :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                                hover
                                sorter>
                                <template #sno="{index}">
                                    <td>
                                        {{index + 1}}
                                    </td>
                                </template>
                                <template #editAction="{item, index}">
                                    <td>
                                        <font-awesome-icon icon="edit" style="color:green; cursor: pointer;" class="mr-4;mb-4;" @click="showEditQuestion(item)"/>
                                    </td>
                                </template>
                                <template #deleteAction="{item, index}">
                                    <td>
                                        <font-awesome-icon icon="trash-alt" style="color:red; cursor: pointer;" class="mt-4;"
                                         @click="deleteQuestion(item)"/>
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal :title="edit_question_title" centered :show.sync="editQuestionModal">
            <CRow>
                <CCol sm="12">
                    <CInput label="Name" type="text" v-model="selected_item.name" disabled></CInput>
                </CCol>
                <CCol sm="12">
                    <CInput label="Year" type="text" v-model="selected_item.year"></CInput>
                </CCol>
                <CCol sm="12">
                    <CInput label="Level" type="text" v-model="selected_item.level"></CInput>
                </CCol>
                <CCol sm="12">
                    <CInput label="Subject" type="text" v-model="selected_item.subject"></CInput>
                </CCol>
            </CRow>
            <template slot="footer">
                <CButton color="secondary" @click="cancelEditQuestionModals">Cancel</CButton>
                <CButton color="success" @click="updateQuestion">Save</CButton>
            </template>
        </CModal>
        <CModal
            title="Confirmation"
            color="success"
            centered
            :show.sync="deleteQuestionModal"
            >
            Are you sure you want to delete a course?
            <template slot="footer">
                <CButton color="secondary" @click="closeConfirmModal">Cancel</CButton>
                <CButton color="success" @click="deleteQuestionSave">OK</CButton>
            </template>
        </CModal>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'; 
import { mapState } from "vuex";
import Vue from 'vue'


export default {
    name:'Course-Tags',
    computed: {
        ...mapState(["course"]),
        ...mapState(["auth"])
    },
    data(){
        return{
            edit_question_title:"Edit Question",
            editQuestionModal:false,
            deleteQuestionModal:false,
            fullPage: true,
            isLoading:false,
            selected_item:{},
            selected_course:{},
            active_courses:[],
            fields:[
                {key: 'sno', label:'SNo.'},
                {key: 'name', label:'Name'},
                {key: 'year', _style:'min-width:30px;', label:'Year'},
                {key: 'level', _style:'min-width:30px;', label:'Level'},
                {key: 'subject', _style:'min-width:30px;', label:'Subject'},
                {key: 'editAction', _style:'min-width:20px;', label:''},
                {key: 'deleteAction', _style:'min-width:20px;', label:''},
            ]
        }
    },
    async mounted(){
        this.isLoading = true
        await this.$store.dispatch("auth/fetchUser");
        // await this.$store.dispatch("course/fetchPermission", 2);
        await this.$store.dispatch("course/fetchCourses");
        this.isLoading = false
    },
    methods:{
        closeConfirmModal(){
            this.deleteQuestionModal = false
        },
        async deleteQuestionSave(){
            if(this.selected_item.id){
                this.isLoading = true;
                await this.$store.dispatch("course/removeCourse", this.selected_item.id);
                // await this.$store.dispatch("tag/fetchTags");
                await this.$store.dispatch("course/fetchCourses");
                this.selected_item = {}
                this.deleteQuestionModal = false
                this.isLoading = false;
            }
        },
        cancelEditQuestionModals(){
            this.editQuestionModal = false
        },
        async updateQuestion(){
            await this.$store.dispatch("course/updateCourse", this.selected_item);
            await this.$store.dispatch("course/fetchCourses");
            this.editQuestionModal = false
        },
        async addToCourses(){
            if(this.selected_course){
                if(this.selected_course.id && this.selected_course.year && this.selected_course.level && this.selected_course.subject)
                { 
                    this.isLoading = true
                    await this.$store.dispatch("course/createActiveCourse", this.selected_course);
                    await this.$store.dispatch("course/fetchCourses");
                    this.isLoading = false
                }
                else{
                        
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: 'Year, Subject and Level required.',
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                }
            }
        },
        showEditQuestion(item){
            this.selected_item = item
            this.editQuestionModal = true
        },
        deleteQuestion(item){
            this.selected_item = item
            this.deleteQuestionModal = true
        },

    }
    
}
</script>
<style>
.save-course-setting{
  background-color:#428bca;
  color:white
}
</style>